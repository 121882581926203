/**
 * Dummy driver classes for replacement via `package.json` in browser builds.
 * Using those classes reduces the build size by one third.
 *
 * If we don't include those dummy classes (and just disable the driver import
 * with `false` in `package.json`) typeorm will throw an error on runtime,
 * even if those driver are not used.
 */

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class MongoDriver {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class MongoQueryRunner {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class MongoEntityManager {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class MongoRepository {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class PostgresDriver {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class SqlServerDriver {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class SapDriver {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class MysqlDriver {}

/**
 * DO NOT IMPORT THIS CLASS -
 * This is a dummy class for replacement via `package.json` in browser builds
 */
export class OracleDriver {}
